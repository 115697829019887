import {Link} from "react-router-dom";

export default function Footer() {
    return (

        <div
            className="footer flex justify-center text-center bg-gray-100 p-2 text-sm  md:text-[12px] w-full">

            <div className="flex justify-between md:flex-col md:justify-center w-[70%] md:w-full">
                <div>
                    تمام حقوق مادی و معنوی این سایت متعلق به
                    <Link to={"/"} className="font-bold">
                        {" "}
                        کیمی پدیا{" "}
                    </Link>{" "}
                    می باشد
                </div>

                <div className='flex justify-center gap-5 '>

                    <div className="menu-item mt-1">
                        <Link to={"https://kimipedia.ir/about-us/"} className=" border-mainColor hover:text-mainColor">درباره
                            ما</Link>
                    </div>
                    <div className="menu-item mt-1">
                        <Link to={"https://kimipedia.ir/guide/"} className=" border-mainColor hover:text-mainColor">راهنمای
                            سایت</Link>
                    </div>
                    <div className="menu-item mt-1">
                        <Link to={"https://kimipedia.ir/roles/"} className=" border-mainColor hover:text-mainColor">قوانین
                            و مقررات</Link>
                    </div>
                    <div className="menu-item mt-1">
                        <Link to={"https://kimipedia.ir/blog/"}
                              className=" border-mainColor hover:text-mainColor">مقالات</Link>
                    </div>

                    <div className="menu-item mt-1">
                        <Link to={"https://kimipedia.ir/news/"}
                              className=" border-mainColor hover:text-mainColor">اخبار</Link>
                    </div>

                </div>

            </div>
        </div>
    );
}
