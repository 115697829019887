import React, {useEffect, useState} from 'react';
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import axios from "axios";
import {CheckAndHandleError} from "../../../axios/HandleAcsess";
import {message, Skeleton} from "antd";
import Header from "../../Headers/Header";
import SelectPackageHeater from "./SelectPackageHeater";
import BuyPackage from "./buyPackage";
import {useLocation, useNavigate} from "react-router-dom";





function HistoryPackage () {

    const navigate = useNavigate();
    const location = useLocation();

    const [getdate, setGetDate] = useState([]);



    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        const date = new Date(timestamp);
        return date.toLocaleString('fa-IR');
    };

    return (

        <div>
            <Header/>

            <div className="grid grid-cols-12 h-screen md:contents md:contents-col-12">

                <div className="col-span-2 bg-mainColor md:fixed md:inset-x-0 md:bottom-0 md:col-span-2">
                    <SelectPackageHeater/>
                </div>

                <div className="col-span-10 md:col-span-10">
                    <div className="flex justify-center items-center md:mb-[100px]">
                        <div className="w-[88%] md:w-[90%]">
                            <div className="mt-[55px] md:mt-4">
                                <div className="flex gap-8 ">

                                    <button
                                        className={`w-[20%] md:w-[49%] md:h-[36px] h-[43px] ${location.pathname === "/SelectPackage/HistoryPackage" ? `border-[3px] rounded-[6px] border-mainColor bg-mainColor text-white` : `rounded-[6px] bg-white text-mainColor`}`}
                                        onClick={() => navigate("/SelectPackage/HistoryPackage")}
                                    >
                                        تاریخچه خرید
                                    </button>

                                    <button
                                        className={`w-[20%] md:w-[49%] md:h-[36px] h-[43px] border-[3px] rounded-[6px] border-mainColor text-mainColor ${location.pathname === "/SelectPackage/buyPackage" ? `border-[3px] rounded-[6px] border-mainColor bg-mainColor text-white` : `rounded-[6px] bg-white text-mainColor`}`}
                                        onClick={() => navigate("/SelectPackage/buyPackage")}
                                    >
                                        خرید
                                        پکیچ جدید
                                    </button>

                                </div>

                                <div>

                                    <table
                                        className="border-separate border-slate-500 mt-[30px] w-full text-center md:text-[12px]">
                                        <thead className="md:text-[13px]">
                                        <tr className="h-[50px]">
                                            <th className="border border-white bg-lightColor">
                                                نام پکیچ انتخابی
                                            </th>
                                            <th className="border border-white bg-lightColor">
                                                مدت اعتبار
                                            </th>
                                            <th className="border border-white bg-lightColor">تاریخ انقضا</th>
                                            <th className="border border-white bg-lightColor">
                                                وضعیت
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            className={`h-[70px] md:text-[11px] bg-white`}>
                                            <td className="border border-white">پلن B</td>
                                            <td className="border border-white">6 ماه</td>
                                            <td className="border border-white">1403/12/12</td>
                                            <td className="border border-white text-green-600">
                                                فعال
                                            </td>
                                        </tr>

                                        <tr
                                            className={`h-[70px] md:text-[11px] bg-[#F9F9F9]`}>
                                            <td className="border border-white">پلن VIP</td>
                                            <td className="border border-white">16 ماه</td>
                                            <td className="border border-white">1402/06/12</td>
                                            <td className="border border-white text-red-600">
                                                منقضی شده
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>


    )
}


export default HistoryPackage