import React, {useEffect, useState, useRef} from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import img from "../../images/Login.webp";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {message, Modal} from "antd";
import Loading from "../Loading/Loading";
import {setUser} from "../slices/registerReducer";
import {handleErrors} from "../../axios/Axios";
import {CheckAndHandleError} from "../../axios/HandleAcsess";
import {useDispatch, useSelector} from "react-redux";


const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInformation = useSelector(state => state.userinformation.userInfo);

    const [timeLine, setTimeLine] = useState(true);

    const [showPassword, setShowPassword] = useState(false);
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSubmitOtp, setShowSubmitOtp] = useState(false)

    const [otp, setOtp] = useState(Array(4).fill(''));
    const inputRefs = useRef([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    // بستن مودال
    const handleOk = () => {
        setIsModalVisible(false);
    };


    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        console.log("run in useEffect")
        if (showSubmitOtp) {
            console.log("run in loop code")
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                        setTimeLine(false)
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        } else {
            setSeconds(0)
            setMinutes(2)
        }
    }, [seconds, showSubmitOtp, minutes]);


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            navigate("/medicineA");
        }
    }, [navigate]);


    function EnterLogin() {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/user/v1/send-otp`, {
            mobile: userName,
        }).then((response) => {
            if (response.status === 200) {
                message.success(response.data.message);
                setLoading(false);
                setShowSubmitOtp(true)
                setOtp(Array(4).fill(''))
            }
        }).catch((error) => {
            setLoading(false);
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!");
            }
            if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else {
                message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...");
            }
        });
    }

    function EnterSubmitOTP() {

        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/user/v1/submit-otp`, {
            mobile: userName,
            code: otp.slice().join(''),
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem("accessToken", response.data.data.accessToken);
                localStorage.setItem("refreshToken", response.data.data.refreshToken);
                setLoading(false);
                navigate("/medicineA");
                axios.get(`${process.env.REACT_APP_API_URL}/user/v1/get-my-profile`, {
                    headers: {
                        Authorization: `Bearer ${response.data.data.accessToken}`
                    }
                })
                    .then(response => {
                        const data = response.data.data;
                        message.success(` ${data?.first_name ? data?.first_name + " جان" : ""} خوش آمدید! `)
                    })
                    .catch(error => {
                        message.success(`خوش آمدید!`)
                    });

            }
        }).catch((error) => {
            setLoading(false);
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!");
            } else if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else {
                message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...");
            }
        });
    }

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleOtpChange = (index, value) => {
        console.log(otp);
        
        const newOtp = [...otp];
        if ((value.length === 1 || value.length === 0) && (value !== " "))
            newOtp[index] = value;
        setOtp(newOtp);
        if (value.length === 1 && (index < otp.length - 1) && (value !== " ")) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
        // || ( (index === 3) &&  (event.key !== 'Backspace') )
        // console.log( (otp[index] !== event.key))
    // || ( (index === otp.length - 1) && (event.key !== 'Backspace') && ( otp[otp.length-1] !== event.key) )
        if ((event.keyCode === 13)  ) {
            EnterSubmitOTP()
        }
    };

    function resendCodeOTP() {
        setMinutes(0)
        setSeconds(10)
        setTimeLine(true)
        setOtp(Array(4).fill(''))
        axios.post(`${process.env.REACT_APP_API_URL}/user/v1/send-otp`, {
            mobile: userName,
        }).then((response) => {
            if (response.status === 200) {
                message.success(response.data.message);
                setLoading(false);
                setShowSubmitOtp(true)
                setOtp(Array(4).fill(''))
            }
        }).catch((error) => {
            setLoading(false);
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!");
            }
            if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else {
                message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...");
            }
        });
    }


    return (
        <div>
            <div className="grid grid-cols-2 md:grid-cols-1">
                <div className="col-span-1">
                    <div className="flex  items-center justify-center w-full h-[100vh]">
                        <div className="md:w-full p-6 w-[50%] flex flex-col justify-center">
                            {showSubmitOtp ?
                                <div className="flex flex-col gap-8">
                                    <div className="flex flex-col justify-center items-center w-[100%]">
                                        <p className="text-[22px] font-[500]">کد تأیید را وارد کنید</p>
                                        <p className="gap-1 flex text-[#8A8D97] text-[16px] mt-4">
                                            کد تایید به شماره
                                            <p className="text-black text-[16px] font-[600]">{userName}</p>
                                            ارسال شد
                                        </p>
                                        <div style={{direction: "ltr"}} className="flex gap-4 mt-6 ">
                                            {otp.map((digit, index) => (
                                                <input
                                                    ref={el => inputRefs.current[index] = el}
                                                    key={index}
                                                    type="text"
                                                    maxLength={1}
                                                    minLength={1}
                                                    value={digit}
                                                    onChange={(e) =>handleOtpChange(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                    className={`text-[26px] md:w-[40px] md:h-[50px] w-[50px] h-[68px] text-center border border-gray-300 rounded-md focus:outline-none focus:border-[#100E34] ${digit ? 'input-filled' : ''}`}
                                                    style={{direction: 'ltr'}}
                                                    pattern="[0-9]"
                                                    autoFocus={index === 0}
                                                    // onKeyUp={()=> index === 3 && EnterSubmitOTP()}
                                                />
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex flex-col justify-center gap-1 items-center">
                                            <div className="flex gap-2">
                                                <p className="text-[16px]">شماره موبایل اشتباه است ؟</p>
                                                <p
                                                    className="text-[16px] text-card4 cursor-pointer"
                                                    onClick={() => {
                                                        setLoading(false)
                                                        setShowSubmitOtp(false);
                                                        setOtp(Array(4).fill(''))
                                                    }}>ویرایش</p>
                                            </div>
                                            <div className="flex gap-2">

                                                <button disabled={timeLine === true}
                                                        className={`${timeLine ? `hover:cursor-not-allowed text-gray-400`: `text-mainColor `}`}
                                                        onClick={resendCodeOTP}> ارسال مجدد کد
                                                </button>
                                                {(minutes === 0) && (seconds === 0) ? null :
                                                    <span> {("0" + minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds)} </span>
                                                }
                                            </div>

                                        </div>


                                        <button
                                            disabled={loading === true}
                                            onClick={EnterSubmitOTP}
                                            className={`flex items-center mt-4 justify-center bg-mainColor w-[100%] rounded-[10px] h-[44px] ${loading ? `hover:cursor-not-allowed` : null}`}
                                        >
                                            {loading ? (
                                                <Loading color={"bg-white"}/>
                                            ) : (
                                                <div>
                                                    <p className="text-white text-[18px]">تایید</p>
                                                </div>
                                            )}
                                        </button>
                                    </div>

                                    <div className="flex justify-center w-[100%]">
                                        <div className="flex items-center gap-1">
                                            <HeadphonesOutlinedIcon
                                                style={{fontSize: "20px"}}
                                                className="text-mainColor"
                                            />
                                            <button
                                                onClick={showModal}
                                                className="text-black text-[14px] md:text-[12px] font-[400] border-none bg-transparent cursor-pointer"
                                            >
                                                تماس با پشتیبانی
                                            </button>
                                        </div>
                                        {/* <div className="flex">
                                            <p className="text-[14px]">حساب کاربری ندارید؟ </p>
                                            <Link to="/Register" className="text-mainColor text-[14px] mr-1">
                                                {" "}
                                                ثبت نام{" "}
                                            </Link>
                                        </div> */}
                                    </div>

                                </div>
                                :
                                <>
                                    <h3 className="text-[28px] md:text-[22px] font-[700] h-[80px]">
                                        ورود به حساب کاربری
                                    </h3>
                                    <div className="flex flex-col gap-8">
                                        <div>
                                            <div className="flex items-center gap-[6px]">
                                                <PersonOutlineIcon
                                                    style={{fontSize: "28px"}}
                                                    className="text-mainColor"
                                                />
                                                <p className="text-[18px] font-[400] md:text-[16px]">شماره موبایل</p>
                                            </div>
                                            <div
                                                className="mt-3 bg-lightColor h-[44px] rounded-[5px]">
                                                <input
                                                    className=" md:placeholder:text-[14px] outline-none focus:outline-gray-400 text-right text-[18px] placeholder:text-[16px] placeholder:text-right  bg-lightColor w-[100%] h-[44px] p-2 rounded-[5px]"
                                                    type="number"
                                                    value={userName}
                                                    placeholder="شماره موبایل خود را وارد کنید ..."
                                                    onChange={(name) => {
                                                        setUserName(name.target.value);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            EnterLogin()
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                            <button
                                                disabled={loading === true}
                                                onClick={EnterLogin}
                                                className={`flex items-center  justify-center bg-mainColor w-[100%] rounded-[10px] h-[44px] ${loading ? `hover:cursor-not-allowed` : null}`}
                                            >
                                                {loading ? (
                                                    <Loading color={"bg-white"}/>
                                                ) : (
                                                    <div>
                                                        <p className="text-white text-[18px]">ورود</p>
                                                    </div>
                                                )}
                                            </button>
                                        </div>

                                        {/* دکمه تماس با پشتیبانی */}
                                        <div className="flex justify-center w-[100%]">
                                            <div className="flex items-center gap-1">
                                                <HeadphonesOutlinedIcon
                                                    style={{fontSize: "20px"}}
                                                    className="text-mainColor"
                                                />
                                                <button
                                                    onClick={showModal}
                                                    className="text-black text-[14px] md:text-[12px] font-[400] border-none bg-transparent cursor-pointer"
                                                >
                                                    تماس با پشتیبانی
                                                </button>
                                            </div>
                                            {/* <div className="flex">
                                                 <p className="text-[14px]">حساب کاربری ندارید؟ </p>
                                                <Link to="/Register" className="text-mainColor text-[14px] mr-1">
                                                    {" "}
                                                    ثبت نام{" "}
                                                </Link>
                                            </div> */}
                                        </div>

                                    </div>
                                </>

                            }

                        </div>
                    </div>
                </div>
                <div className="col-span-1 md:hidden">
                    <img className="max-h-[100vh] w-full h-full" src={img}/>
                </div>
            </div>

            {/* مودال تماس با پشتیبانی */}
            <Modal
                title="تماس با پشتیبانی"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null} // حذف دکمه‌های OK و Cancel
            >
                <p> با شماره 09123456789 تماس بگیرید.</p>
                <a href="tel:09123456789">
                    <button className="bg-mainColor text-white rounded-[8px] px-4 py-2 mt-4">
                        تماس با شماره
                    </button>
                </a>
            </Modal>
        </div>
    );
};

export default Login;
