import React, {useState, useEffect} from 'react'
import Header from "../../Headers/Header";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TuneIcon from "@mui/icons-material/Tune";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ClearIcon from "@mui/icons-material/Clear";
import {message, Skeleton} from "antd";
import {Link} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Footer from "../../Footer/Footer";
import {ShowDisplayFilterMobile} from "../MedicineA/MedicineA";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {CheckAndHandleError} from "../../../axios/HandleAcsess";
import {setValidity} from "../../slices/registerReducer";
import {useDispatch} from "react-redux";


function ChangedPriceMedicineA() {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [currentItems, setCurrentItems] = useState([]);

    // Filter Handle
    const [firstDate, setFirstDate] = useState()
    const [secondDate, setSecondDate] = useState()
    const [showRemoveFilter, setShowRemoveFilter] = useState(false)

    const [msFirstDate, setMsFirstDate] = useState()
    const [msSecondDate, setMsSecondDate] = useState()

    useEffect(() => {

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/medicine/v1/get-list-medicines-a-sort-change-price-user`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                from: msFirstDate,
                to: msSecondDate
            }
        })
            .then(res => {
                setLoading(false)
                setCurrentItems(res.data.data)
                dispatch(setValidity(true))
            }).catch((error) => {
            setLoading(false)
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }
            if (error.response && error.response.status <= 499) {
                if (error.response.status === 401) {
                    CheckAndHandleError(error)
                } else if (error.response.status === 403)
                    dispatch(setValidity(false))
                else message.error(error.response.data.message);
                setCurrentItems([])
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
        })


    }, [msSecondDate, msFirstDate])


    function check(s) {
        var PersianOrASCII = /[آ-ی]|([a-zA-Z])/;
        var m;
        if ((m = s.match(PersianOrASCII)) !== null) {
            if (m[1]) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '-';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };


    function FilterHandler(first, second) {

        const gregorianFirstDate = new Date(first)
        gregorianFirstDate.setHours(0)
        gregorianFirstDate.setMinutes(0)
        gregorianFirstDate.setSeconds(0)
        const gregorianSecondDate = new Date(second)
        gregorianSecondDate.setHours(23)
        gregorianSecondDate.setMinutes(59)
        gregorianSecondDate.setSeconds(59)

        if (first || second) {
            setMsFirstDate(gregorianFirstDate.getTime())
            setMsSecondDate(gregorianSecondDate.getTime())
            setShowRemoveFilter(true)
        } else {
            setShowRemoveFilter(false)
            setMsSecondDate()
            setMsFirstDate()
            setFirstDate()
            setSecondDate()
        }

    }


    return (

        <div className="flex flex-col min-h-[100vh]">
            <Header/>
            <div className="main md:p-2 p-7 mb-12" style={{flexGrow: 1}}>
                <div
                    className="md:gap-3 md:flex md:flex-col article-header flex flex-row justify-between items-center md:items-start">
                    <div className="article-title flex flex-col gap-2">
                        <p className="md:text-[11px] md:flex font-normal text-mainColor">
                            &gt;&gt; قیمت های جدید دارو های دسته A
                        </p>
                        <h2 className="text-[32px] md:text-[15px] font-black">
                            تغییرات قیمت دارو های دسته A (کلیه داروها - داروهای آزاد)
                        </h2>
                    </div>


                    <div
                        className="md:gap-3 md:flex md:flex-col article-header flex flex-row justify-end items-center md:items-start md:my-[0px] my-[30px]"
                    >
                        <div className="search flex flex-col md:w-full w-[65%] md:justify-between">

                            <div
                                className="filter flex justify-between font-bold md:text-[12px] text-[14px] h-[26px] text-mainColor mb-[4px]">
                                <div
                                >
                                    <TuneIcon style={{fontSize: "22px"}}/> بازه زمانی
                                </div>
                                {showRemoveFilter ?
                                    <div className="hover:cursor-pointer"
                                         onClick={() => FilterHandler("", "")}
                                    >
                                        حذف فیلتر
                                    </div>
                                    :
                                    null
                                }

                            </div>

                            <div className="search-input flex gap-2 md:flex md:justify-end">
                                <div className="">
                                    <DatePicker
                                        calendar={persian}
                                        locale={persian_fa}
                                        onPositionChange
                                        value={firstDate}
                                        onChange={setFirstDate}
                                        placeholder="از تاریخ"
                                        style={{
                                            outline: 'none',
                                            borderRadius: '8px',
                                            border: '2px solid #e0e3e7',
                                            textAlign: 'right',
                                            fontSize: '16px',
                                            backgroundColor: '#f7f7f7',
                                            height: '40px',
                                            width: '100%',
                                            marginLeft: '5px'
                                        }}
                                    />
                                </div>
                                <div>
                                    <DatePicker
                                        calendar={persian}
                                        locale={persian_fa}
                                        onPositionChange
                                        value={secondDate}
                                        onChange={setSecondDate}
                                        placeholder="تا تاریخ"
                                        style={{
                                            outline: 'none',
                                            borderRadius: '8px',
                                            border: '2px solid #e0e3e7',
                                            textAlign: 'right',
                                            fontSize: '16px',
                                            backgroundColor: '#f7f7f7',
                                            width: '100%',
                                            height: '40px'
                                        }}
                                    />
                                </div>
                                <button className="rounded-[8px] bg-mainColor h-[40px] text-white px-2"
                                        onClick={() => FilterHandler(firstDate, secondDate)}
                                >
                                    <ManageSearchIcon/>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="article-main mt-4">
                    <div>
                        <table className="text-center w-full text-sm rounded-[8px] border-2 border-gray-100">
                            <thead>
                            <tr className="bg-[#F9F9F9]">
                                <th></th>
                                <th>نام برند</th>
                                <th className="md:hidden">نام ژنریک</th>
                                <th className="md:hidden">کارخانه سازنده</th>
                                <th className="md:hidden">قیمت واحد با ارزش افزوده</th>
                                <th>تاریخ تغییر قیمت</th>
                                <th>عملیات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? (
                                [...Array(7)].map((_, index) => (
                                    <tr key={index}>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className=" md:hidden box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className=" md:hidden box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className=" md:hidden box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                currentItems.length > 0 ?
                                    currentItems.map((item, index) => (

                                        <tr key={index}
                                            className={index % 2 === 0 ? 'bg-white' : 'bg-[#F9F9F9]'}>
                                            <td className="box-border text-mainColor">{index + 1}</td>
                                            <td>{item.brand_name_fa.length > 30 ? <>{item.brand_name_fa.slice(0, 30) ?? "-"} ...</> : item.brand_name_fa ?? "-"}</td>
                                            <td className="md:hidden">{item.generic_name_fa.length > 30 ? <>{item.generic_name_fa.slice(0, 30) ?? "-"} ...</> : item.generic_name_fa ?? "-"}</td>
                                            <td className="md:hidden">{item.manufacturing_factory.length > 30 ? (check(item.manufacturing_factory) ? <>{item.manufacturing_factory.slice(0, 30) ?? "-"} ...</> : <>... {item.manufacturing_factory.slice(0, 30) ?? "-"}</>) : item.manufacturing_factory ?? "-"}</td>
                                            <td className="md:hidden">{(item?.price_OA ? item.price_OA?.[item.price_OA?.length - 1].price : "-")}</td>
                                            <td>{formatTimestamp(item?.price_OA && item.price_OA?.[item.price_OA?.length - 1].insert_date) ?? "-"}</td>
                                            <td className="text-thirdColor">
                                                <Link to={`/medicine-A/${item._id}`}>
                                                    <div
                                                        className="flex items-center font-[300] justify-center gap-1">
                                                        <div
                                                            className="md:hidden rounded-[5px] p-1 w-auto h-auto flex items-center justify-center">
                                                            <SearchIcon style={{fontSize: "20px"}}/>
                                                        </div>
                                                        <span>نمایش اطلاعات</span>
                                                    </div>
                                                </Link>
                                            </td>
                                        </tr>


                                    )) :
                                    <tr className="bg-lightColor">
                                        <td className="text-[16px]" colSpan="7">دارویی یافت نشد!</td>
                                    </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <Footer/>


        </div>


    )

}


export default ChangedPriceMedicineA