import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import {useDispatch, useSelector} from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {setCompleteProfile, setUser, setValidity} from "../slices/registerReducer";


function HeaderTiltle() {

    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownMedicine, setShowDropdownMedicine] = useState(false);
    const [showDropdownProfile, setShowDropdownProfile] = useState(false);


    const profileInformation = useSelector(state => state.userinformation.userInfo);
    const completeProfile = useSelector(state => state.userinformation.completeProfile);
    const validity = useSelector(state => state.userinformation.validity);


    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    const handlelogOut = () => {
        localStorage.clear()
        navigate("/")
        dispatch(setCompleteProfile(true))
        dispatch(setValidity(true))
    }


    return (
        <ul className="flex flex-row gap-10 justify-center md:inline h-full">
            <li className="menu-item md:mt-[15px]">
                <div>
                    <div
                        className={`grid hover:text-mainColor hover:cursor-pointer`}
                        onClick={() => {
                            setShowDropdown(!showDropdown);
                            setShowDropdownProfile(false)
                            setShowDropdownMedicine(false)
                        }}>
                        <h4 className=" select-none border-mainColor">اطلاعات دارویی<KeyboardArrowDownIcon
                            className={`hover:text-mainColor ${showDropdown ? `rotate-180` : null}`}/></h4>
                    </div>
                    {showDropdown ?
                        <div className="absolute bg-white border-2 border-mainColor rounded-[6px] mt-3">
                            <ul className="w-[260px] md:w-[90%]">
                                <li className="hover:cursor-pointer hover:text-mainColor m-2 md:text-[12px]"
                                    onClick={() => {
                                        navigate("/medicineA");
                                        setShowDropdown(false)
                                    }}>اطلاعات دارویی (دسته بندی A)
                                </li>
                                <hr className="border-1 border-mainColor "/>
                                <li className="hover:cursor-pointer hover:text-mainColor m-2 md:text-[12px]"
                                    onClick={() => {
                                        navigate("/medicineB");
                                        setShowDropdown(false)
                                    }}>اطلاعات دارویی (دسته بندی B)
                                </li>
                                <hr className="border-1 border-mainColor "/>
                                <li className="hover:cursor-pointer hover:text-mainColor m-2 md:text-[12px]"
                                   >
                                    <Link to={"https://kimipedia.ir/drug-interactions/"}>تداخلات دارویی</Link>
                                </li>
                            </ul>
                        </div> : null}
                </div>
            </li>


            <li className="menu-item md:mt-[15px]">
                <div>
                    <div
                        className={`grid hover:text-mainColor hover:cursor-pointer`}
                        onClick={() => {
                            setShowDropdownMedicine(!showDropdownMedicine);
                            setShowDropdownProfile(false)
                            setShowDropdown(false)
                        }}>
                        <h4 className=" select-none border-mainColor">تغییرات قیمت دارویی<KeyboardArrowDownIcon
                            className={`hover:text-mainColor ${showDropdownMedicine ? `rotate-180` : null}`}/></h4>
                    </div>
                    {showDropdownMedicine ?
                        <div className="absolute bg-white border-2 border-mainColor rounded-[6px] mt-3">
                            <ul className="w-[260px] md:w-[90%]">
                                <li className="hover:cursor-pointer hover:text-mainColor m-2 md:text-[12px]"
                                    onClick={() => {
                                        navigate("/medicineA/changedPrice")
                                        setShowDropdownMedicine(false)
                                    }}>داروهای دسته A
                                </li>
                                <hr className="border-1 border-mainColor "/>
                                <li className="hover:cursor-pointer hover:text-mainColor m-2 md:text-[12px]"
                                    onClick={() => {
                                        navigate("/medicineB/changedPrice")
                                        setShowDropdownMedicine(false)
                                    }}>داروهای دسته B
                                </li>
                            </ul>
                        </div> : null}
                </div>
            </li>

            <li className="menu-item md:mt-[15px]">
                <div>
                    <div
                        className={`flex hover:text-mainColor hover:cursor-pointer gap-1`}
                        onClick={() => {
                            setShowDropdownProfile(!showDropdownProfile);
                            setShowDropdown(false)
                            setShowDropdownMedicine(false)
                        }}>
                        <h4 className=" select-none border-mainColor">پروفایل کاربری<KeyboardArrowDownIcon
                            className={`hover:text-mainColor ${showDropdownProfile ? `rotate-180` : null}`}/></h4>
                    </div>
                    {showDropdownProfile ?
                        <div className="absolute w-[220px] bg-white border-2 border-mainColor rounded-[6px] mt-3 z-10">
                            <ul className="">
                                <li className="flex justify-between gap-10 hover:cursor-pointer m-2 md:text-[15px]"
                                    onClick={() => navigate("/Profile")}>
                                    <h4>
                                        {profileInformation.name && profileInformation.lastName ?
                                            <>{profileInformation.name} {profileInformation.lastName}</>
                                            :
                                            <>بدون نام</>
                                        }
                                    </h4>
                                    <h4 className="text-mainColor"><KeyboardArrowLeftIcon/></h4>
                                </li>
                                <hr className="border-1 border-mainColor"/>
                                <li onClick={handlelogOut}
                                    className="flex justify-between gap-10 hover:cursor-pointer m-2 md:text-[15px]">
                                    <div className="text-red-600">خروج از حساب</div>
                                    <div className="text-red-500"><LogoutIcon/></div>
                                </li>
                            </ul>
                        </div> : null}
                </div>
            </li>
        </ul>
    )
}

export default HeaderTiltle;