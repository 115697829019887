import React, {useState, useEffect} from "react";
import axios from "axios";
import {message} from "antd";
import Loading from "../../Loading/Loading";
import env from "react-dotenv";
import {useNavigate, useLocation} from "react-router-dom";
import {CheckAndHandleError} from "../../../axios/HandleAcsess";




function AddNewProblem(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const [title, setTitle] = useState("");
    const [report, setReport] = useState("");
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);


    function sendDate () {

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/ticket/v1/new_ticket`, {
            title: title,
            report: report,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then( (response) => {
            setLoading(false)
            message.success(response.data.message);
            props.setAddProblem(false)
        }).catch( (error) => {
            setLoading(false)
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }
            if ( error.response && error.response.status <= 499) {
                if (error.response.status === 401)
                    CheckAndHandleError(error);
                else message.error(error.response.data.message);
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
        })
    }



    return (

        <div className="flex justify-center items-center mt-[50px]">

            <div className="w-[88%] h-[75vh] flex flex-col justify-between">

                <div className="gap-9 grid">

                    <div className="text-[25px] md:text-[20px]">ثبت گزارش جدید</div>
                    {/*<div className="bg-lightColor h-[60px] rounded-[10px] flex justify-start">*/}
                        <input className="bg-lightColor h-[60px] rounded-[10px] flex justify-start p-[12px] focus:rounded-[10px] focus:ring-2 focus:ring-mainColor md:placeholder:text-[16px] md:mr-0 mr-[10px] outline-none"
                               placeholder={"عنوان گزارش وارد کنید ..."}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    {/*</div>*/}

                    {/*<div className="bg-lightColor rounded-[10px] h-[300px] flex justify-start">*/}
                                <textarea className=" bg-lightColor rounded-[10px] h-[300px] flex justify-start md:placeholder:text-[16px] focus:ring-mainColor focus:rounded-[10px] focus:ring-2 p-[12px] md:mr-0 mr-[10px] w-full resize-none outline-none"
                                          placeholder={"متن گزارش وارد کنید ..."}
                                          onChange={(e) => setReport(e.target.value)}
                                />
                    {/*</div>*/}

                </div>


                <div className="flex justify-end gap-4">
                    <button
                        disabled={loading === true}
                        className={`border-[2px] w-[20%] md:w-[48%] md:h-[36px] h-[49px] bg-mainColor flex justify-center items-center text-white rounded-[8px] ${loading && `hover:cursor-not-allowed`}`}
                        onClick={sendDate}>
                        {loading ? (
                            <Loading color={"bg-white"}/>
                        ) : (<p>ارسال گزارش</p>)}
                    </button>
                    <button onClick={() => props.setAddProblem(false)}
                            className="border-[2px] w-[20%] md:w-[48%] md:h-[36px] h-[49px] border-mainColor text-mainColor rounded-[8px]">انصراف
                    </button>

                </div>
            </div>

        </div>

    )

}

export default AddNewProblem;