import Header from "../../Headers/Header";
import Footer from "../../Footer/Footer";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState, useEffect} from "react";
import axios from "axios";
import {CheckAndHandleError} from "../../../axios/HandleAcsess";
import {message, Skeleton, Image} from 'antd';
import Loading from "../../Loading/Loading";
import {TEChart} from "tw-elements-react";
import TimelineIcon from '@mui/icons-material/Timeline';
import UploadIcon from '@mui/icons-material/Upload';
// import upLoadFile from "../../../images/Upload-Icon-Logo-Download-Free-PNG.png"





export default function MedicineDetailA() {

    const navigate = useNavigate();
    const location = useLocation();


    const {medicineId} = useParams();
    const [medicine, setMedicine] = useState({});

    const [showModal, setShowModal] = useState(false);

    const [showChartModal, setShowChartModal] = useState(false);
    const [dataChartModal, setDataChartModal] = useState({});

    const [loading, setLoading] = useState(false);
    const [error, setErrore] = useState(false);

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);



    useEffect(() => {
        setLoading(true);
        window.scroll({ top: 0, behavior: 'smooth' });
        axios.get(`${process.env.REACT_APP_API_URL}/medicine/v1/get-medicine-a-by-id/${medicineId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        })
            .then((response) => {
                setMedicine(response.data.data);
                setLoading(false)
            }).catch((error) => {
            setLoading(false)
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }
            if (error.response && error.response.status <= 499) {
                if (error.response.status === 401) {
                    CheckAndHandleError(error)
                } else
                    message.error(error.response.data.message);
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")

        });
    }, [medicineId]);


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };

    function handleClick() {
        setShowModal(!showModal);
    }

    function handleClosePage() {
        navigate(-1);
    }

    function ChartForModal(data, text) {
        setDataChartModal({
            main: data,
            text: text,
        })
        setShowChartModal(true)
    }

    function separate(Number) {
        Number += '';
        Number = Number.replace(',', '');
        const x = Number.split('.');
        let y = x[0];
        const z = x.length > 1 ? '.' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(y))
            y = y.replace(rgx, '$1' + ',' + '$2');
        return y + z;
    }

    return (
        <>
            <Header/>
            <div className="main">
                <div className="title h-[150px] bg-no-repeat bg-cover flex items-center justify-center">
                    <h2 className="text-center w-full bg-[#ffffff36] pt-4 pb-4 font-extrabold text-[20px]">
                        {medicine?.brand_name_fa ?? "بدون نام"}
                    </h2>
                </div>

                <div>


                    <div
                        className="information pt-10 w-[80%] m-auto md:flex md:flex-col md:w-[88%] grid grid-cols-2 gap-x-10 gap-y-7 auto-cols-auto">

                        <div className="rounded-[100%] flex justify-center">
                            {medicine?.photo ?
                                <Image.PreviewGroup>
                                    <Image
                                        width={180} height={180} preview={{mask: false}}
                                        className="rounded-[100%] hover:cursor-pointer hover:opacity-85"
                                        src={`${process.env.REACT_APP_API_URL}${medicine?.photo?.path}`}
                                    />
                                </Image.PreviewGroup>
                                :
                                <div
                                    className="bg-gray-100 flex justify-center items-center w-[180px] h-[180px] hover:cursor-context-menu rounded-[30%]">
                                    بدون تصویر
                                </div>
                            }
                        </div>

                        <div className="flex flex-col justify-between gap-8">

                            <div className="card-info">
                                <h3 className="info-title">
                                    <ChevronLeftIcon className="text-mainColor"/>
                                    نام برند دارویی به فارسی
                                </h3>
                                <div
                                    className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[40px]">
                                    {loading ?
                                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                        medicine?.brand_name_fa ?? "-"}
                                </div>
                            </div>

                            <div className="card-info">
                                <h3 className="info-title">
                                    <ChevronLeftIcon className="text-mainColor"/>
                                    نام برند دارویی به لاتین
                                </h3>
                                <div
                                    className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[40px]">
                                    {loading ?
                                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                        medicine?.brand_name_la ?? "-"}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div
                        className="information pt-10 w-[80%] m-auto md:flex md:flex-col md:w-[88%] grid grid-cols-2 gap-x-10 gap-y-7 auto-cols-auto pb-10">

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                نام ژنریک دارویی به فارسی
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                    : medicine?.generic_name_fa ?? "-"}
                            </div>
                        </div>

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                نام ژنریک دارویی به انگلیسی
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.generic_name_la ?? "-"}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center pb-10">
                        <div
                            className={`grid ${medicine?.added_value ? `grid-cols-4` : `grid-cols-2`} gap-2 information w-[80%] m-auto md:flex md:flex-col md:w-[88%] gap-x-10 gap-y-7 auto-cols-auto`}>

                            <div className="flex gap-3">

                                <div className="card-info w-full">
                                    <h3 className="info-title">
                                        <ChevronLeftIcon className="text-mainColor"/>
                                        قیمت واحد بدون ارزش افزوده
                                    </h3>
                                    <div
                                        className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between">
                                        {loading ?
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                            (medicine?.price_O ? separate(medicine?.price_O?.[medicine.price_O?.length - 1].price) : "-")}
                                        <div className="text-mainColor hover:cursor-pointer"
                                             onClick={() => ChartForModal(medicine?.price_O, "قیمت واحد بدون ارزش افزوده")}>
                                            <TimelineIcon/>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {medicine?.added_value &&
                                <div className="flex gap-3">

                                    <div className="card-info w-full">
                                        <h3 className="info-title">
                                            <ChevronLeftIcon className="text-mainColor"/>
                                            قیمت واحد با ارزش افزوده
                                        </h3>
                                        <div
                                            className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between">
                                            {loading ?
                                                <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                                (medicine?.price_OA ? separate(medicine.price_OA?.[medicine.price_OA?.length - 1].price) : "-")}
                                            <div className="text-mainColor hover:cursor-pointer"
                                                 onClick={() => ChartForModal(medicine?.price_OA, "قیمت واحد با ارزش افزوده")}>
                                                <TimelineIcon/>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            }


                            <div className="flex gap-3">

                                <div className="card-info w-full">
                                    <h3 className="info-title">
                                        <ChevronLeftIcon className="text-mainColor"/>
                                        قیمت جعبه بدون ارزش افزوده
                                    </h3>
                                    <div
                                        className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between">
                                        {loading ?
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                            (medicine?.price_B ? separate(medicine.price_B?.[medicine.price_B?.length - 1].price) : "-")}
                                        <div className="text-mainColor hover:cursor-pointer"
                                             onClick={() => ChartForModal(medicine?.price_B, "قیمت جعبه بدون ارزش افزوده")}>
                                            <TimelineIcon/>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            {medicine?.added_value  &&

                                <div className="flex gap-3 col-span-1">

                                    <div className="card-info w-full">
                                        <h3 className="info-title">
                                            <ChevronLeftIcon className="text-mainColor"/>
                                            قیمت جعبه با ارزش افزوده
                                        </h3>
                                        <div
                                            className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between">
                                            {loading ?
                                                <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                                (medicine?.price_BA ? separate(medicine.price_BA?.[medicine.price_BA?.length - 1].price) : "-")}
                                            <div className="text-mainColor hover:cursor-pointer"
                                                 onClick={() => ChartForModal(medicine?.price_BA, "قیمت جهبه با ارزش افزوده")}>
                                                <TimelineIcon/>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            }


                        </div>
                    </div>

                    <div
                        className="information w-[80%] m-auto md:flex md:flex-col md:w-[88%] grid grid-cols-2 gap-x-10 gap-y-7 auto-cols-auto pb-10">

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                اجزای تشکیل دهنده دارو</h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.components ?? "-"}</div>
                        </div>

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                صاحب امتیاز
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.concessionaire ?? "-"} </div>
                        </div>

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                کارخانه سازنده
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 w-full rounded-lg min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.manufacturing_factory ?? "-"}
                            </div>
                        </div>
                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                شرکت وارد کننده
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.importer_company ?? "-"}</div>
                        </div>
                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                شرکت های توزیع کننده
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[48px]">
                                <p className="text-balance w-[90%]">
                                    {loading ?
                                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                        medicine?.distribution_companies ?? "-"}
                                </p>
                            </div>
                        </div>

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                کشور سازنده</h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.manufacturingـcountry ?? "-"}</div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <div
                            className="grid grid-cols-4 gap-2 information w-[80%] m-auto md:flex md:flex-col md:w-[88%] gap-x-10 gap-y-7 auto-cols-auto">

                            <div className="card-info w-full">
                                <h3 className="info-title">
                                    <ChevronLeftIcon className="text-mainColor"/>
                                    ارزش افزوده
                                </h3>
                                <div
                                    className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]g">
                                    {loading ?
                                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                        (medicine?.added_value ? 'دارد' : 'ندارد') ?? "-"}</div>
                            </div>


                            <div className="flex gap-3">

                                <div className="card-info w-full">
                                    <h3 className="info-title">
                                        <ChevronLeftIcon className="text-mainColor"/>
                                        تعداد در بسته بندی
                                    </h3>
                                    <div
                                        className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                        {loading ?
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                            medicine?.quantity_in_pack ?? "-"}
                                    </div>
                                </div>

                            </div>


                            <div className="flex gap-3">

                                <div className="card-info w-full">
                                    <h3 className="info-title">
                                        <ChevronLeftIcon className="text-mainColor"/>
                                        کد برند دارو
                                    </h3>
                                    <div
                                        className="items-center font-sans flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                        {loading ?
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                            medicine?.brand_code ?? "-"}
                                    </div>
                                </div>

                            </div>


                            <div className="flex gap-3 col-span-1">

                                <div className="card-info w-full">
                                    <h3 className="info-title">
                                        <ChevronLeftIcon className="text-mainColor"/>
                                        کد ژنریک دارو
                                    </h3>
                                    <div
                                        className="items-center font-sans flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                        {loading ?
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                            medicine?.generic_code ?? "-"}</div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div
                        className="information pt-10 w-[80%] m-auto md:flex md:flex-col md:w-[88%] grid grid-cols-2 gap-x-10 gap-y-7 auto-cols-auto pb-20">


                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                گروه دارویی
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.pharmaceutical_group ?? "-"}</div>
                        </div>
                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                طبقه بندی دارویی </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.drug_classification ?? "-"}</div>
                        </div>
                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                توضیحات
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    medicine?.description ?? 'بدون توضیحات'}</div>
                        </div>

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                دارو های مرتبط
                            </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[48px]">
                                {loading ?
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                    :
                                    <div className="flex flex-wrap">
                                        {medicine?.related?.map((iteam, index) =>
                                            <p key={index}>{iteam.brand_name_fa ?? "-"} -</p>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="card-info">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                لینک اطلاعات </h3>
                            <div
                                className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                {loading ?
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>

                                    :
                                    <Link to={medicine?.information_link ?? ""}
                                          className={` ${medicine?.information_link && `hover:text-mainColor`}`}>
                                        {medicine?.information_link ?? 'بدون لینک'}
                                    </Link>
                                }

                            </div>
                        </div>


                        <div className="buttons flex mt-6 flex-row gap-4 col-start-2 justify-end items-end">
                            <button
                                className="border-[3px] h-[48px]  border-mainColor rounded-lg text-mainColor pt-2 pb-2 w-full flex gap-2 justify-center"
                                onClick={handleClick}
                            >
                                <ErrorOutlineIcon/>
                                ارسال گزارش
                            </button>
                            <button
                                className="bg-mainColor h-[48px] text-white pt-2 pb-2 col-start-1 col-end-2 w-full rounded-lg"
                                onClick={handleClosePage}
                            >
                                بستن
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>

            {showModal ? (
                    <Modal medicineID={medicineId} showModal={showModal} setShowModal={setShowModal}/>
                ) :
                null
            }
            {showChartModal ? (
                    <ChartModal data={dataChartModal} showModal={setShowChartModal}/>
                ) :
                null
            }
        </>
    );
}

export function Modal({showModal, setShowModal, medicineID}) {

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)


    const [upLoadFileID, setUploadFileID] = useState("")
    const [upLoadFileShow, setUploadFileShow] = useState("")

    function handleClick() {
        setShowModal(!showModal);
    }

    function sendReportDataToApi() {

        const dateReport = {
            title: title,
            report: description,
            medicenID_A: medicineID,
        }

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/report/v1/new_Report`, dateReport, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then((response) => {
            message.success(response.data.message)
            setLoading(false)
            setShowModal(false)
        }).catch((error) => {
            setLoading(false)
            CheckAndHandleError(error)
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }
            if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
        })

    }


    // // file Handler
    // function readFile() {
    //     document.querySelector('#fileUpload')?.click();
    //
    // }
    //
    //
    // function sendProfileImageToApi(e) {
    //
    //     const formData = new FormData();
    //     formData.append('file', e.target.files[0]);
    //
    //     setLoadingFile(true)
    //     axios.post(`${process.env.REACT_APP_API_URL}/files/v1/upload`, formData, {
    //         headers: {
    //             "Content-Type": "multipart/form-data",
    //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //         },
    //     }).then((res) => {
    //         setLoadingFile(false)
    //         console.log(res)
    //         message.success("فایل شما با موفقیت بارگذاری شد")
    //         setUploadFileID(res.data.data._id)
    //         setUploadFileShow(`${process.env.REACT_APP_API_URL} / ${res.data.data.fileName}`)
    //     }).catch((err) => {
    //         setLoadingFile(false)
    //         message.error(err.response.data.message)
    //         console.log(err)
    //     });
    // }

    return (
        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity">
            <div className="flex min-h-full items-end justify-center p-3 text-center">
                <div
                    className="modal relative transform overflow-hidden rounded-lg bg-white w-[60%] md:w-[88%] m-auto flex flex-col gap-5">

                    <div className="close-bar p-7 flex justify-between border-b-2">
                        <div className="bar-titel">ارسال گزارش</div>
                        <div className="cursor-pointer hover:opacity-50 bar-icon text-mainColor" onClick={handleClick}>
                            <CloseIcon/>
                        </div>
                    </div>
                    <div className="detail p-7 flex flex-col gap-10 font-normal justify-end">

                        {/*<div className="flex justify-start items-center gap-3">*/}
                        {/*    <div className="flex justify-center items-center w-[45px] h-[45px] rounded-[50%] border-2">*/}
                        {/*        {loadingFile ?*/}
                        {/*            <Loading color={"bg-black"} className="w-[50%] h-[50%]"/>*/}
                        {/*            :*/}
                        {/*            <img className="w-[65%] h-[65%]"*/}
                        {/*                 src={upLoadFileShow ? upLoadFileShow : upLoadFile}*/}
                        {/*            />*/}
                        {/*        }*/}

                        {/*    </div>*/}
                        {/*    <label htmlFor="file" className="text-[15px] text-card3 cursor-pointer">*/}
                        {/*        <UploadIcon className="text-card3"/>*/}
                        {/*        فایل خود را انتخاب کنید*/}
                        {/*    </label>*/}
                        {/*    <input className="md:hidden" id="file" style={{visibility: "hidden"}} type="file"*/}
                        {/*           name="fileName"*/}
                        {/*           onClick={() => readFile("fileUpload")}*/}
                        {/*           onChange={sendProfileImageToApi}*/}
                        {/*    ></input>*/}

                        {/*</div>*/}

                        <input
                            className="w-full text-right text-[15px] focus:outline-none focus:ring-2 focus:ring-mainColor placeholder:text-right bg-lightColor p-3 rounded-lg"
                            type="text"
                            placeholder="عنوان گزارش"
                            autoFocus
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <textarea
                            className="w-full text-right h-[200px] focus:outline-none focus:ring-2 resize-none outline-none focus:ring-mainColor text-[15px] placeholder:text-right bg-lightColor p-3 rounded-lg"
                            name="paragraph_text"
                            placeholder="متن گزارش خود را وارد کنید"
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        <button
                            disabled={loading === true}
                            className={`p-3 bg-mainColor rounded-lg text-white md:w-1/2 md:m-auto w-2/6 self-end ${loading && `hover:cursor-not-allowed`}`}
                            onClick={sendReportDataToApi}>
                            {loading ?
                                <div className="flex p-2 justify-center">
                                    <Loading color={"bg-white"}/>
                                </div>
                                : "ارسال گزارش"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function ChartModal({data, showModal}) {


    const time = []
    const price = []


    useEffect(() => {
        for (var i = 0; i <= data.main.length - 1; i++) {
            time.push(formatTimestamp(data.main[i].insert_date));
        }

        for (var j = 0; j <= data.main.length - 1; j++) {
            price.push(data.main[j]?.price);
        }
    }, [data])


    function formatTimestamp(timestamp) {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    }


    return (
        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity">
            <div className="flex min-h-full items-end justify-center p-3 text-center">
                <div
                    className="modal relative transform overflow-hidden rounded-lg bg-white w-[60%] md:w-[88%] m-auto flex flex-col gap-5">

                    <div className="close-bar p-7 flex justify-between border-b-2">
                        <div className="bar-titel">{data.text}</div>
                        <div className="cursor-pointer hover:opacity-50 bar-icon text-mainColor"
                             onClick={() => showModal(false)}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="detail md:p-0 p-7 flex flex-col gap-10 font-normal justify-end">
                        <TEChart
                            type="line"
                            backgroundColor="#00C0C0"
                            data={{
                                labels: time,
                                datasets: [
                                    {
                                        backgroundColor: "#00C0C0",
                                        borderColor: "#00C0C0",
                                        label: "قیمت",
                                        data: price,
                                    },
                                ],
                            }}
                        />
                    </div>

                </div>
            </div>
        </div>

    )
}