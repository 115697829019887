import React, {useState, useEffect} from 'react'
import Header from "../../Headers/Header";
import Footer from "../../Footer/Footer";
import {Link} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import axios from "axios";
import {message, Skeleton} from "antd";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {CheckAndHandleError} from "../../../axios/HandleAcsess";


function ChangedPriceMedicineB() {


    const [loading, setLoading] = useState(false);

    const [currentItems, setCurrentItems] = useState([]);

    // Filter Handle
    const [firstDate, setFirstDate] = useState()
    const [secondDate, setSecondDate] = useState()
    const [showRemoveFilter, setShowRemoveFilter] = useState(false)

    const [msFirstDate, setMsFirstDate] = useState()
    const [msSecondDate, setMsSecondDate] = useState()

    useEffect(() => {

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/medicine/v1/get-list-medicines-b-sort-change-price-user`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                from: msFirstDate,
                to: msSecondDate
            }
        })
            .then(res => {
                setLoading(false)
                setCurrentItems(res.data.data)
            }).catch((error) => {
            setLoading(false)
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }
            if (error.response && error.response.status <= 499) {
                if (error.response.status === 401) {
                    CheckAndHandleError(error)
                } else{
                    setCurrentItems([])
                    message.error(error.response.data.message);
                }
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
        })


    }, [msSecondDate, msFirstDate])


    function check(s) {
        var PersianOrASCII = /[آ-ی]|([a-zA-Z])/;
        var m;
        if ((m = s.match(PersianOrASCII)) !== null) {
            if (m[1]) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '-';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };



    function FilterHandler(first, second) {
        const gregorianFirstDate = new Date(first)
        gregorianFirstDate.setHours(0)
        gregorianFirstDate.setMinutes(0)
        gregorianFirstDate.setSeconds(0)
        const gregorianSecondDate = new Date(second)
        gregorianSecondDate.setHours(23)
        gregorianSecondDate.setMinutes(59)
        gregorianSecondDate.setSeconds(59)


        if (first || second) {
            setMsFirstDate(gregorianFirstDate.getTime())
            setMsSecondDate(gregorianSecondDate.getTime())
            setShowRemoveFilter(true)
        }else {
            setShowRemoveFilter(false)
            setMsSecondDate()
            setMsFirstDate()
            setFirstDate()
            setSecondDate()
        }
    }


    return (

        <div className="flex flex-col min-h-[100vh]">
            <Header/>
            <div className="main md:p-2 p-7 mb-12" style={{flexGrow: 1}}>
                <div
                    className="md:gap-3 md:flex md:flex-col article-header flex flex-row justify-between items-center md:items-start">
                    <div className="article-title flex flex-col gap-2">
                        <p className="md:text-[11px] md:flex font-normal text-mainColor">
                            &gt;&gt; قیمت های جدید دارو های دسته B
                        </p>
                        <h2 className="text-[32px] md:text-[15px] font-black">
                            تغییرات قیمت دارو های دسته B (کلیه داروها - داروهای آزاد)
                        </h2>
                    </div>

                    <div
                        className="md:gap-3 md:flex md:flex-col article-header flex flex-row justify-end items-center md:items-start md:my-[0px] my-[30px]"
                    >
                        <div className="search flex flex-col md:w-full w-[65%] md:justify-between">

                            <div
                                className="filter flex justify-between font-bold md:text-[12px] text-[14px] h-[26px] text-mainColor mb-[4px]">
                                <div className="hover:cursor-pointer"
                                >
                                    <TuneIcon style={{fontSize: "22px"}}/> بازه زمانی
                                </div>
                                {showRemoveFilter &&
                                    <div className="hover:cursor-pointer"
                                         onClick={() => FilterHandler("", "")}
                                    >
                                        حذف فیلتر
                                    </div>
                                }

                            </div>

                            <div className="search-input flex gap-2 md:flex md:justify-end">
                                <div className="">
                                <DatePicker
                                        calendar={persian}
                                        locale={persian_fa}
                                        onPositionChange
                                        value={firstDate}
                                        onChange={setFirstDate}
                                        placeholder="از تاریخ"
                                        style={{
                                            outline: 'none',
                                            borderRadius: '8px',
                                            border: '2px solid #e0e3e7',
                                            textAlign: 'right',
                                            fontSize: '16px',
                                            backgroundColor: '#f7f7f7',
                                            height: '40px',
                                            width: '100%',
                                            marginLeft: '5px'
                                        }}
                                    />
                                </div>
                                <div>
                                    <DatePicker
                                        calendar={persian}
                                        locale={persian_fa}
                                        onPositionChange
                                        value={secondDate}
                                        onChange={setSecondDate}
                                        placeholder="تا تاریخ"
                                        style={{
                                            outline: 'none',
                                            borderRadius: '8px',
                                            border: '2px solid #e0e3e7',
                                            textAlign: 'right',
                                            fontSize: '16px',
                                            backgroundColor: '#f7f7f7',
                                            width: '100%',
                                            height: '40px'
                                        }}
                                    />
                                </div>
                                <button className="rounded-[8px] bg-mainColor h-[40px] text-white px-2"
                                        onClick={() => FilterHandler(firstDate, secondDate)}
                                >
                                    <ManageSearchIcon/>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="article-main mt-4">
                    <div>
                        <table className="text-center w-full text-sm rounded-[8px] border-2 border-gray-100">
                            <thead>
                            <tr className="bg-[#F9F9F9]">
                                <th></th>
                                <th className="md:hidden">نام برند</th>
                                <th>نام ژنریک</th>
                                <th className="md:hidden">شرکت تولید کننده</th>
                                <th className="md:hidden">قیمت مصرف کننده</th>
                                <th>تاریخ تغییر قیمت</th>
                                <th>عملیات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? ([...Array(7)].map((_, index) => (<tr key={index}>
                                <td className="box-border text-mainColor">
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                </td>
                                <td className="box-border text-mainColor">
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                </td>
                                <td className="box-border text-mainColor">
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                </td>
                                <td className="box-border text-mainColor">
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                </td>
                                <td className=" md:hidden box-border text-mainColor">
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                </td>
                                <td className=" md:hidden box-border text-mainColor">
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                </td>
                                <td className=" md:hidden box-border text-mainColor">
                                    <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                </td>
                            </tr>))) : (currentItems.length > 0 ? currentItems.map((item, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-[#F9F9F9]'}>
                                            <td className="box-border text-mainColor">{index + 1}</td>
                                            <td className="md:hidden">{item.brand_name_fa?.length > 30 ? <>{item.brand_name_fa.slice(0, 30) ?? "-"} ...</> : item.brand_name_fa ?? "-"}</td>
                                            <td>{item.generic_name_fa?.length > 30 ? <> {item.generic_name_fa.slice(0, 30) ?? "-"} ...</> : item.generic_name_fa ?? "-"}</td>
                                            <td className="md:hidden">{item.producer_company?.length > 30 ? (check(item.producer_company) ? <>{item.producer_company.slice(0, 30) ?? "-"} ...</> : <>... {item.producer_company.slice(0, 30) ?? "-"}</>) : (item.producer_company ?? "-")}</td>
                                            <td>{(item?.price_consumer ? item?.price_consumer?.[item.price_consumer?.length - 1].price : "-")}</td>
                                            <td>{formatTimestamp(item.price_updated_at) ?? "-"}</td>
                                            <td className="text-thirdColor">
                                                <Link to={`/medicine-B/${item._id}`}>
                                                    <div className="flex items-center font-[300] justify-center gap-1">
                                                        <div
                                                            className="rounded-[5px] md:hidden p-1 w-auto h-auto flex items-center justify-center">
                                                            <SearchIcon/>
                                                        </div>
                                                        <span>نمایش اطلاعات</span>
                                                    </div>
                                                </Link>
                                            </td>
                                        </tr>)) :
                                    <tr className="bg-lightColor">
                                        <td className="text-[16px]" colSpan="7">دارویی یافت نشد!</td>
                                    </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <Footer/>


        </div>


    )
}


export default ChangedPriceMedicineB