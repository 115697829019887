import React, {useEffect} from "react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Header from "../../Headers/Header";
import SelectPackageHeater from "./SelectPackageHeater";
import HistoryPackage from "./HistoryPackage";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {daDK} from "@mui/material/locale";
import {Skeleton} from "antd";
import {CheckAndHandleError} from "../../../axios/HandleAcsess";
import PaymentIcon from '@mui/icons-material/Payment';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


function BuyPackage() {

    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = React.useState(true);

    const [getData, setGetData] = React.useState([]);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState("");
    const [totalDocs, setTotalDocs] = React.useState("");


    useEffect(() => {

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/packages/v1/get-package-list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                limit : limit,
                page: page
            }
        }).then((res) => {
            setLoading(false)
            setGetData(res.data.data.docs);
            setTotalPage(res.data.data.totalPages)
            setTotalDocs(res.data.data.totalDocs)
        }).catch((err) => {
            CheckAndHandleError(err)
            setLoading(false)
        })

    }, [page]);

    return (

        <div>
            <Header/>

            <div className="grid grid-cols-12 h-screen md:contents md:contents-col-12">

                <div className="col-span-2 bg-mainColor md:fixed md:inset-x-0 md:bottom-0 md:col-span-2">
                    <SelectPackageHeater/>
                </div>

                <div className="col-span-10 md:col-span-10">
                    <div className="flex justify-center items-center md:mb-[100px]">
                        <div className="w-[88%] md:w-[90%]">
                            <div className="mt-[55px] md:mt-4">

                                <div className="flex gap-8 ">

                                    <button
                                        className={`w-[20%] md:w-[49%] md:h-[36px] h-[43px] ${location.pathname === "/SelectPackage/HistoryPackage" ? `border-[3px] rounded-[6px] border-mainColor bg-mainColor text-white` : `rounded-[6px] border-[3px] border-mainColor bg-white text-mainColor`}`}
                                        onClick={() => navigate("/SelectPackage/HistoryPackage")}
                                    >
                                        تاریخچه خرید
                                    </button>

                                    <button
                                        className={`w-[20%] md:w-[49%] md:h-[36px] h-[43px] border-[3px] rounded-[6px] border-mainColor text-mainColor ${location.pathname === "/SelectPackage/buyPackage" ? `border-[3px] rounded-[6px] border-mainColor bg-mainColor text-white` : `rounded-[6px]  bg-white text-mainColor`}`}
                                        onClick={() => navigate("/SelectPackage/buyPackage")}
                                    >
                                        خرید
                                        پکیچ جدید
                                    </button>

                                </div>

                                <table
                                    className="border-separate border-slate-500 mt-[30px] w-full text-center md:text-[12px]">
                                    <thead className="md:text-[13px]">
                                    <tr className="h-[50px]">
                                        <th className="border border-white bg-lightColor">
                                            نام پکیج
                                        </th>
                                        <th className="border border-white bg-lightColor">
                                            مدت اعتبار (روز)
                                        </th>
                                        <th className="border border-white bg-lightColor">قیمت</th>
                                        <th className="border border-white bg-lightColor">
                                            عملیات
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loading ? (
                                            [...Array(6)].map((_, index) => (
                                                <tr key={index}>
                                                    <td className="box-border text-mainColor">
                                                        <Skeleton active paragraph={{rows: 1, width: "100%"}}
                                                                  title={false}/>
                                                    </td>
                                                    <td className="box-border text-mainColor">
                                                        <Skeleton active paragraph={{rows: 1, width: "100%"}}
                                                                  title={false}/>
                                                    </td>
                                                    <td className="box-border text-mainColor">
                                                        <Skeleton active paragraph={{rows: 1, width: "100%"}}
                                                                  title={false}/>
                                                    </td>
                                                    <td className="box-border text-mainColor">
                                                        <Skeleton active paragraph={{rows: 1, width: "100%"}}
                                                                  title={false}/>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        getData.length > 0 ?
                                            getData.map((item, index) =>
                                                <tr
                                                    className={`h-[70px] md:text-[11px] bg-white ${index % 2 === 0 ? 'bg-white' : 'bg-[#F9F9F9]'}`}
                                                    key={index}>
                                                    <td className="border border-white">{item.name}</td>
                                                    <td className="border border-white">{item.days}</td>
                                                    <td className="border border-white">{item.price}</td>
                                                    <td className="border border-white text-green-600 hover:cursor-pointer">
                                                        <PaymentIcon className="ml-1" />
                                                        پرداخت
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td className="bg-lightColor" colSpan="4">هیچ پکیجی یافت نشد.</td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>


                                {totalDocs > limit ?

                                    <div className="pagination transition ease-in-out delay-150 p-5 text-mainColor flex items-center justify-center gap-3">
                                        <button
                                            className={` ${page <= 1 ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                                            onClick={() => setPage(page - 1)}
                                            disabled={page <= 1}
                                        >
                                            <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                                                <ArrowForwardIcon style={{fontSize: "18px"}}/>
                                            </div>
                                        </button>
                                        <p className="text-black md:text-[12px] font-bold">{page} از {totalPage} صفحه</p>
                                        <button
                                            className={` ${page >= totalPage ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                                            onClick={() => setPage(page + 1)}
                                            disabled={page >= totalPage}
                                        >
                                            <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                                                <ArrowBackIcon style={{fontSize: "18px"}}/>
                                            </div>
                                        </button>
                                    </div>

                                    : null}


                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>

    )
}


export default BuyPackage;