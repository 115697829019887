import React, {useEffect} from 'react';
import errorPage from "../../images/404-img-error.jpg";
import Header from "../Headers/Header";
import Footer from "../Footer/Footer";
import {useNavigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";


function ErrorPage404() {

    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = localStorage.getItem("accessToken");


    useEffect(() => {

        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    function HandelPage () {

        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }else navigate("/medicineA")
    }

    return (

        <div>
            <Header/>


            <div className="flex md:flex-col-reverse md:justify-end justify-center h-[84vh]">


                <div className="flex flex-col justify-center gap-4">

                    <div className="flex flex-col justify-between items-center text-[72px] md:text-[35px]">

                        <div className="flex text-mainColor font-bold text-[90px] md:text-[50px] font-sans gap-6">
                            <p>ERROR</p>
                            <p>404</p>
                        </div>

                        <p className="text-[#585858] font-[500]">اوه... ظاهرا گم شده اید!</p>

                    </div>

                    <div className="flex flex-col justify-center  items-center text-gray-400">

                        <p>متاسفه صفحه ای که به دنبال آن هستید یافت نشد</p>

                    </div>

                    <div className="flex justify-center">
                        <button className="w-[20%] md:w-[50%] h-[5vh] rounded-[6px] bg-mainColor text-white"
                        onClick={HandelPage}>
                            صفحه اصلی</button>
                    </div>

                </div>


                <div className="mt-auto mb-auto md:mt-5 md:mb-1 flex justify-center opacity-75">
                    <img style={{marginRight: "1rem"}} src={errorPage} alt="404 page error" width={"80%"}/>
                </div>

            </div>


            <Footer/>

        </div>
    )

}


export default ErrorPage404;