import React from 'react';
import { useNavigate } from "react-router-dom";




function PackageValidity() {

    const navigate = useNavigate();

    return (

        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-100 transition-opacity">
            <div className="flex min-h-full p-5">
                <div className="rounded-lg bg-white w-[50%] md:w-[88%] p-10 md:p-10 m-auto gap-5">

                    <div className="grid md:flex md:flex-col md:mt-3">

                        <div className="text-center mb-2">
                            برای ادامه لطفا <b style={{ color: 'red', fontWeight: "900" }}>پکیج</b> تهیه فرمایید!
                        </div>


                    </div>


                    <div className="flex justify-center gap-[10px] mt-3">
                        <button
                            className="border-[3px] px-6 h-[36px] md:px-0 md:w-full justify-center text-[14px] rounded-[25px] border-mainColor bg-mainColor text-white"
                            onClick={() => navigate("/SelectPackage/buyPackage")}
                        >
                            رفتن به صفحه انتخاب پکیج
                        </button>
                    </div>

                </div>


            </div>
        </div>
    )
}


export default PackageValidity;