import React from "react";
import PackageCard from "./packageCard";
import {useLocation, useNavigate} from "react-router-dom";


const Step2 = () => {

    const navigate = useNavigate();
    const loaction = useLocation();
    const date = loaction.state

    return (
        <>
            <div className="mt-8 flex flex-col justify-center items-center">
                <div className=" w-[90%] flex justify-between md:flex md:gap-5 md:flex-col">
                    <div>
                        <p className="text-[18px]">پکیج مورد نظر را انتخاب کنید ...</p>
                    </div>
                    <div className="flex gap-3 md:flex md:flex-col">
                        <div className="gap-2 flex ">
                            <div
                                className="flex rounded-full text-mainColor border-2 border-mainColor justify-center w-[28px] h-[28px] ">
                                <span className="text-[16px]">1</span>
                            </div>
                            <p className="text-[#7E7E81]">ورود اطلاعات</p>
                            <span className="text-[#7E7E81] md:hidden"> . . . </span>
                        </div>
                        <div className="col-span-1 gap-2 flex">
                            <div
                                className=" flex items-center rounded-full text-white bg-mainColor justify-center w-[28px] h-[28px] ">
                                <span className="text-[16px]">2</span>
                            </div>
                            <p className=""> انتخاب پکیج </p>
                            <span className="md:hidden"> . . . </span>
                        </div>
                        <div className="col-span-1 gap-2 flex">
                            <div
                                className=" flex rounded-full text-mainColor border-2 border-mainColor justify-center w-[28px] h-[28px] ">
                                <span className="text-[16px]">3</span>
                            </div>
                            <p className="text-[#7E7E81]"> تایید اطلاعات و پرداخت </p>
                            <span className="text-[#7E7E81] md:hidden"> . . . </span>
                        </div>
                    </div>
                </div>
                <div className="w-[90%] h-[150px] flex items-center">
                    <div className="flex w-full items-center gap-3">
                        <div className="flex-shrink-0">
                            <p className="text-mainColor text-[24px] font-light whitespace-nowrap">
                                انتخاب پکیج{" "}
                            </p>
                        </div>
                        <div className="flex-grow h-[3px] bg-mainColor"></div>
                    </div>
                </div>
            </div>
            <PackageCard/>
        </>
    );
};

export default Step2;
