import "./App.css";
import {Routes, Route, Navigate} from "react-router-dom";
import Login from "./components/Login & Register/Login";
import Register from "./components/Login & Register/Register";
import Step1 from "./components/Login & Register/Step1";
import Step2 from "./components/Login & Register/Step2";
import Step3 from "./components/Login & Register/Step3";
import MedicineA from "./components/Medicine/MedicineA/MedicineA"; // drug page
import MedicineB from "./components/Medicine/MedicineB/MedicineB";
import MedicineDetailA from "./components/Medicine/MedicineA/MedicineDetailA";
import Profile from "./components/Information/Profile/Profile";
import SelectPackage from "./components/Information/SelectPackage/SelectPackage";
import ManagementComponent from "./components/Information/Reports/ManagementComponent";
import MedicineDetailB from "./components/Medicine/MedicineB/MedicineDetailB";
import {useEffect, useRef} from "react";
import ErrorPage404 from "./components/404 Page/404ErrorPage";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ProfileNotComplete from "./components/ErrorProfileAndPackage/ProfileNotComplete";
import PackageValidity from "./components/ErrorProfileAndPackage/PackageValidity";
import axios from "axios";
import {setCompleteProfile, setUser} from "./components/slices/registerReducer";
import {CheckAndHandleError} from "./axios/HandleAcsess";
import HistoryPackage from "./components/Information/SelectPackage/HistoryPackage";
import BuyPackage from "./components/Information/SelectPackage/buyPackage";
import ChangedPriceMedicineA from "./components/Medicine/ChangedPriceMedicine/ChangedPriceMedicineA";
import ChangedPriceMedicineB from "./components/Medicine/ChangedPriceMedicine/ChangedPriceMedicineB";


function App() {


    const location = useLocation();
    const dispatch = useDispatch();
    const profileInformation = useSelector(state => state.userinformation.completeProfile);
    const validity = useSelector(state => state.userinformation.validity);


    useEffect(() => {

        if (!(location.pathname === "/")) {
            axios.get(`${process.env.REACT_APP_API_URL}/user/v1/get-my-profile`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(response => {
                    const data = response.data.data;
                    const transformedProfile = {
                        name: data?.first_name,
                        lastName: data?.last_name,
                    };
                    dispatch(setUser(transformedProfile));
                })
                .catch(error => {
                    CheckAndHandleError(error);
                });
        }

    }, [location.pathname])


    useEffect(() => {

        if (!(location.pathname === "/")) {

            axios.get(`${process.env.REACT_APP_API_URL}/user/v1/check-my-profile`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then((res) => {
                    dispatch(setCompleteProfile(res.data.data.profile_completed));
                }).catch((err) => {
                CheckAndHandleError(err);
            })
        }

    }, [location.pathname]);


    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])


    return (
        <div
            onCopy={(e) => {
                e.preventDefault();
                return false;
            }}
            onDragStart={(e) => {
                e.preventDefault();
                return false;
            }}
        >
            <Routes>
                <Route path="*" element={<Navigate to="/NotFound" replace/>}/>
                <Route path="/NotFound" element={<ErrorPage404/>}/>
                <Route path="/" element={<Login/>}/>
                <Route path="/Register" element={<Register/>}>
                    <Route path="step1" element={<Step1/>}/>
                    <Route path="step2" element={<Step2/>}/>
                    <Route path="step3" element={<Step3/>}/>
                </Route>
                <Route path="/medicineA" element={<MedicineA/>}/>
                <Route path="/medicineB" element={<MedicineB/>}/>
                <Route path="/medicineA/changedPrice" element={<ChangedPriceMedicineA />}/>
                <Route path="/medicineB/changedPrice" element={<ChangedPriceMedicineB />}/>
                <Route path="/medicine-A/:medicineId" element={<MedicineDetailA/>}/>
                <Route path="/medicine-B/:medicineId" element={<MedicineDetailB/>}/>
                <Route path="/Profile" element={<Profile/>}/>

                <Route path="/SelectPackage" >
                    <Route path="HistoryPackage" element={<HistoryPackage/>}/>
                    <Route path="buyPackage" element={<BuyPackage/>}/>
                </Route>


                <Route path="/Reports" element={<ManagementComponent/>}/>

            </Routes>


            {
                location.pathname === "/Profile" || location.pathname === "/Reports" || location.pathname === "/SelectPackage/buyPackage" || location.pathname === "/SelectPackage/HistoryPackage" || location.pathname === "/NotFound" || location.pathname === "/" ?
                    null
                    :
                    profileInformation ?
                        validity ?
                            null
                            :
                            <PackageValidity/>
                        :
                        <ProfileNotComplete/>
            }
        </div>
    )
        ;
}


export default App;
