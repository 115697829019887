import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  userInfo: {
    name: "",
    lastName:"",
  },
  completeProfile: true,
  validity: true,
  step: 1,
  error: null,
};

const registerSlice = createSlice({
  name: "userinformation",
  initialState,
  reducers: {

    setUserInformation: (state, action) => {
      state.userInfo = action.payload;
      state.error = null;
    },

    setCompleteProfile: (state, action) => {
      state.completeProfile = action.payload;
      state.error = null;
    },

    setValidity: (state, action) => {
      state.validity = action.payload;
      state.error = null;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
    nextStep: (state) => {
      if (state.step < 3) state.step += 1;
    },
    prevStep: (state) => {
      if (state.step > 1) state.step -= 1;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },

  },
});

export const {
  setUserInformation: setUser,
  setCompleteProfile: setCompleteProfile,
  setValidity : setValidity,
  setError: setHandleError,
  nextStep,
  prevStep,
  setStep,
} = registerSlice.actions;

export default registerSlice.reducer;
