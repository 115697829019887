import React from 'react';
import Loading from "../Loading/Loading";
import {useNavigate} from "react-router-dom";




function ProfileNotComplete() {

    const navigate = useNavigate();

    return (
        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-100 transition-opacity">
            <div className="flex min-h-full p-5">
                <div className="rounded-lg bg-white w-[50%] md:w-[88%] p-10 md:p-10 m-auto gap-5">

                    <div className="grid md:flex md:flex-col md:mt-3">

                        <div className="text-center mb-2">
                           برای استفاده از امکانات سامانه ابتدا اطلاعات هویتی خود را تکمیل کنید!
                        </div>

                        <div className=" flex md:flex-col gap-8 justify-center">

                            {/*<div className="flex justify-end md:justify-normal h-[6ch] w-full">*/}
                            {/*    <div className="flex bg-gray-100 rounded-[5px] relative top-0 left-0 w-[70%]">*/}
                            {/*        <input*/}
                            {/*            type="text"*/}
                            {/*            // value={firstName}*/}
                            {/*            id="firstName"*/}
                            {/*            // onChange={(e) => setFirstName(e.target.value)}*/}
                            {/*            placeholder=" "*/}
                            {/*            className="input1 outline-none bg-transparent w-full h-full text-[18px]"*/}
                            {/*        />*/}
                            {/*        <label className="label1 text-gray-400 hover:cursor-text" htmlFor="firstName">*/}
                            {/*            نام*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="flex justify-start md:justify-normal h-[6ch] w-full">*/}
                            {/*    <div className="flex bg-gray-100 rounded-[5px] relative top-0 left-0 w-[70%]">*/}
                            {/*        <input*/}
                            {/*            type="text"*/}
                            {/*            // value={lastName}*/}
                            {/*            id="lastName"*/}
                            {/*            // onChange={(e) => setLastName(e.target.value)}*/}
                            {/*            placeholder=" "*/}
                            {/*            className="input1 outline-none bg-transparent w-full h-full text-[18px]"*/}
                            {/*        />*/}
                            {/*        <label className="label1 text-gray-400 hover:cursor-text" htmlFor="lastName">*/}
                            {/*            نام خانوادگی*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>

                    </div>


                    <div className="flex justify-center gap-[10px] mt-3">
                        <button
                            className="border-[3px] px-6 h-[36px] md:px-0 md:w-full justify-center text-[14px] rounded-[25px] border-mainColor bg-mainColor text-white"
                            onClick={() => navigate("/Profile")}
                        >
                           رفتن به صفحه ویرایش کاربر
                        </button>
                    </div>

                </div>


            </div>
        </div>
    )
}


export default ProfileNotComplete